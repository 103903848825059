<template>
  <b-card>
    <b-row>
      <b-col
          cols="2"
          class="d-flex"
      >
        <div class="d-flex justify-content-start align-items-center h-100 mr-4">
          <b-button
              variant="outline-secondary"
              :to="{name: this.$route.name , query: this.$route.query}"
          >
            <feather-icon icon="ArrowLeftIcon" />
            <span class="align-middle ml-50">{{ $i18n.t('back') }}</span>
          </b-button>
        </div>
      </b-col>
      <!-- article Info: Left col -->
      <b-col
          cols="2"
          class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-avatar
              :src="article.thumbUrl"
              :alt="article.sku"
              style="width: fit-content; height: fit-content"
              rounded
          />
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
          cols="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="DropletIcon"
                  class="mr-25"
              />
              <span class="font-weight-bold">{{ $i18n.t('reports.table.fields.sku') }}</span>
            </th>
            <td class="pb-50">
              {{ article.sku }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
              />
              <span class="font-weight-bold">{{ $i18n.t('reports.table.fields.supplier') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ article.supplier }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="StarIcon"
                  class="mr-25"
              />
              <span class="font-weight-bold">{{ $i18n.t('reports.table.fields.supplierNumber') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ article.supplierNumber }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="MinusIcon"
                  class="mr-25"
              />
              <span class="font-weight-bold">{{ $i18n.t('reports.table.fields.season') }}</span>
            </th>
            <td class="pb-50">
              {{ article.season }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>
