<template>
  <div>
    <app-breadcrumb :items="breadcrumbs" />
    <loading :show="loading.page">
      <article-info
          v-if="articleData"
          :article="articleData"
      />
      <filter-reports
          ref="filter"
          :filter.sync="filter"
          :enable="isEnableFilter"
          show-date-filter
          show-is-ag-filter
          show-seasons-filter
          show-suppliers-filter
          show-platform-groups-filter
          @initFilter="initFilter()"
      />
      <b-row>
        <b-col
            cols="12"
            md="12"
            class="mb-md-0 mb-2"
        >
          <chart-trading-report
              ref="chart"
              :filter="filter"
              :is-loading-page="loading.page"
              :is-loading.sync="loading.chart"
          />
        </b-col>
      </b-row>
      <table-trading-report
          ref="table"
          export-csv
          export-option
          export-with-image-option
          pagination
          :filter="filter"
          searchable
          :fields-search="['sku','supplierNumber']"
          :is-loading-page="loading.page"
          :is-loading-table.sync="loading.table"
          :is-init-filter="isInitFilter"
          @change-page="changePage"
      >
        <template #cell(thumbUrl)="data">
          <router-link
              v-if="!$route.params.sku"
              :to="{name: 'trading-overview',params: { sku: data.item.sku },query: $route.query}"
          >
            <img
                height="40"
                :src="data.value"
                :alt="data.item.sku"
                class="image"
            >
          </router-link>
        </template>
        <template #cell(sku)="data">
          <router-link
              v-if="!$route.params.sku"
              :to="{name: 'trading-overview',params: { sku: data.item.sku },query: $route.query}"
          >
            {{ data.item.sku }}
          </router-link>
          <div v-else>
            {{ data.item.sku }}
          </div>
        </template>
      </table-trading-report>
    </loading>
  </div>
</template>
<script>
import ChartTradingReport from "@/views/reports/components/Chart.vue";
import TableTradingReport from "@/views/reports/components/table/index.vue";
import ArticleInfo from "@/views/reports/components/ArticleInfo.vue"
import AppBreadcrumb from "@/layouts/components/AppBreadcrumb.vue";
import FilterReports from "@/views/reports/components/FilterReports.vue";

export default {
  components: {
    FilterReports,
    ArticleInfo,
    AppBreadcrumb,
    ChartTradingReport,
    TableTradingReport,
  },
  data() {
    return {
      filter: null,
      articleData: null,
      enableFilter: false,
      loading: {
        page: false,
        chart: false,
        table: false,
      },
      isInitFilter: false,
    }
  },
  computed: {
    getFormatFieldByLocale() {
      return item => {
        if (typeof (item) === 'object') {
          return item[this.lang()]
        }
        return item
      }
    },
    isEnableFilter() {
      return !this.loading.page && !this.loading.chart && !this.loading.table
    },
    breadcrumbs() {
      if (this.articleData) {
        return [
          {
            text: 'reports.names.trading-overview',
            active: false,
            to: {name: 'trading-overview', query: this.$route.query},
          },
          {
            text: this.articleData.sku,
            active: true,
            withoutTranslate: true,
          },
        ]
      }
      return [
        {
          text: 'reports.names.trading-overview',
          active: true,
        }
      ]
    },
  },
  watch: {
    '$route.params.sku': function skuChange() {
      this.loading.page = true
      this.articleData = null
      this.$refs.table.handleFilterChange()
      this.$refs.chart.getData()
    },
    loading: {
      handler(){
        if (!this.loading.chart && !this.loading.table) {
          this.loading.page = false
        }
      },
      deep: true,
    }
  },
  methods: {
    changePage(newVal) {
      this.articleData = newVal
    },
    initFilter() {
      this.isInitFilter = true;
      this.$refs.table.refresh()
    }
  }
}

</script>
